import './SiNoDropdown.css';

import React from 'react';

const SiNoDropdown = ({ handleValueChange, isEditable, placeholder }) => {
    const handleOptionSelect = (zone) => {
        handleValueChange({ target: { value: zone.id } });
    };

    return (
        <>
            <div className='flex'>
                <label className="inline-label">
                    {placeholder}:
                </label>
                <select
                    onChange={handleOptionSelect}
                    className="dropdown inline-input-si-no"
                    disabled={!isEditable}
                >
                    <option value='-'>-</option>
                    <option value='no'>No</option>
                    <option value='si'>Si</option>
                </select>
            </div>
        </>
    );
};

export default SiNoDropdown;
