import React, { useState, useEffect } from 'react';

import './Planilla.css';
import '../MainMenu.css';
import Header from '../Header';
import icon_camera from '../assets/icon_camera.png';
import icon_save from '../assets/icon_save.png';
import incidentUploader from '../helper/incidentUploader';
import axiosInstance from '../axiosConfig'
import { saveFormDataInIdb, getFormDataFromIdb, deleteFormData } from '../helper/idb';
import { useNavigate, useLocation } from 'react-router-dom';
import { fetchZones, fetchPlazas, fetchAssignable } from './../helper/api';
import { API_URL } from './../Constants';
import FilterableDropdown from './../components/FilterableDropdown';
import SiNoDropdown from '../components/SiNoDropdown';
import MultiSelectModal from '../components/MultiSelectModal';
import { fetchTasks } from '../helper/api';

const MAX_IMAGES = 15;

function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

function Planilla({ type, handleLogout }) {
    const [descripcion, setDescripcion] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [zonaValue, setZonaValue] = useState('');
    const [plazaValue, setPlazaValue] = useState('');
    const [hechoValue, setHechoValue] = useState('');
    const [tareasValues, setTareasValue] = useState('');
    const [selectedValues, setSelectedValues] = useState([]);
    const [asignarAValue, setAsignarAValue] = useState('');
    const [capturedImages, setCapturedImages] = useState([]);
    const [zones, setZones] = useState([]);
    const [plazas, setPlazas] = useState([]);
    const [asignables, setAsignables] = useState([]);
    const [options, setOptions] = useState([]);

    const location = useLocation();
    const anomaly = location.state?.anomaly;

    const isEditable = !anomaly;

    useEffect(() => {
        if (anomaly) {
            console.log('Anomaly:', anomaly);
            setDescripcion(anomaly.name);
            setZonaValue(anomaly.zone_id);
            setPlazaValue(anomaly.lugar_id);
            setObservaciones(anomaly.comment);
            setAsignarAValue(anomaly.user_id_assigned);

            const photos = anomaly.photos ? anomaly.photos.split(', ') : [];
            const transformedPhotos = photos.map(photo => API_URL + photo.replace('../', ''));
            setCapturedImages(transformedPhotos);

            console.log(anomaly)
        }
        async function fetchData() {
            setOptions(await fetchTasks('limpieza'));
        }
        fetchData();
        
    }, [anomaly]);

    const navigate = useNavigate();

    const clearAllFields = () => {
        // setVolume('');
        // setDate('');
    };

    const handleBack = () => {
        clearAllFields();
        navigate(-1);
    };

    const saveImagesByIncidentId = async (id, capturedImages) => {
        try {
            for (const image of capturedImages) {
                const img = new Image();
                img.src = image;

                img.onload = async () => {
                    const canvas = document.createElement('canvas');
                    const ctx = canvas.getContext('2d');

                    const originalWidth = img.width;
                    const originalHeight = img.height;

                    let newWidth = originalWidth;
                    let newHeight = originalHeight;

                    if (originalWidth > originalHeight) {
                        if (originalWidth > 1024) {
                            newWidth = 1024;
                            newHeight = (originalHeight * 1024) / originalWidth;
                        }
                    } else {
                        if (originalHeight > 1024) {
                            newHeight = 1024;
                            newWidth = (originalWidth * 1024) / originalHeight;
                        }
                    }
                    canvas.width = newWidth;
                    canvas.height = newHeight;
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    const resizedImage = canvas.toDataURL('image/jpeg');
                    const byteString = atob(resizedImage.split(',')[1]);
                    const mimeString = 'image/jpeg';
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });

                    const formData = new FormData();
                    formData.append('uploaded_file', blob, generateRandomString(6) + '.jpg');

                    try {
                        const response = await axiosInstance.post('/php/pwa_upload_image.php?incidentId=' + id + '&stage=0', formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        });

                        if (response?.status !== 200) {
                            throw new Error('Network response was not ok');
                        }

                        const result = response.data;
                        console.log('Data saved successfully:', result);
                    } catch (error) {
                        console.error('Error saving data image:', error);
                        await saveFormDataInIdb({ incident_id: id, image });
                    }
                }
            }
        } catch (error) {
            await saveFormDataInIdb({ incident_id: id, image });
        }
    };

    useEffect(() => {
        fetchZones().then((zonesData) => {
            if (Array.isArray(zonesData)) {
                setZones(zonesData);
            }
        });

        const handleOnline = async () => {
            console.log('handleOnline called');
            const formDataList = await getFormDataFromIdb();
            console.log('formDataList', formDataList);
            for (const data of formDataList) {
                if (data.body) {
                    await saveAnomalyToServer(data.body);
                    await saveImagesByIncidentId(data.id, data.images);
                    await deleteFormData(data.id);
                } else {
                    await saveImagesByIncidentId(data.incident_id, [data.image]);
                    await deleteFormData(data.id);
                }
            }
        };

        const checkForRecordsToSync = async () => {
            if (navigator.onLine) {
                console.log('Online, checking for records to sync');
                await handleOnline();
            } else {
                console.log('Offline, skipping sync');
            }
        };

        checkForRecordsToSync();

        // window.addEventListener('online', handleOnline);
        // return () => {
        //     window.removeEventListener('online', handleOnline);
        // };
        return () => {
            console.log('Anomaly unmounted');
        };
    }, []);

    const getBody = () => {
        const fields = {
            lugar_id: plazaValue,
            zone_id: zonaValue,
            date_started: Date.now() / 1000,
            date_expected: Date.now() / 1000 + 3600 * 24 * 7,
            lat: '0',
            lon: '0',
            accuracy: '1',
            altitude: '0',
            utcOffset: '-3',
            urgency_id: '2',
            user_id_assigned: asignarAValue,
            name: descripcion,
            comment: observaciones,
        };
        return incidentUploader.getBody(fields);
    }

    const generateForm = (body) => {
        const formDataReturn = new FormData();
        for (const key in body) {
            if (body.hasOwnProperty(key)) {
                formDataReturn.append(key, body[key]);
            }
        }
        return formDataReturn;
    }

    const saveAnomalyToServer = async (body) => {
        try {
            const response = await axiosInstance.post('/php/pwa_save_incident.php', generateForm(body));
            console.log('response', response);
            if (!response || response.status !== 200) {
                throw new Error('Network response was not ok');
            }
            const result = response.data;
            // errorMessage.innerHTML = 'Data saved successfully: ' + JSON.stringify(response);
            await saveImagesByIncidentId(result.id, capturedImages);
        } catch (error) {
            console.error('Error saving data: (catch else)', error);
            await saveFormDataInIdb({ body, images: capturedImages });
        }
    }

    const handleSave = async () => {
        alert('Este botón será habilitado pronto');
        return;
        let errors = false;
        if (descripcion.trim() === '') {
            errors = true;
            const errorMessage = document.getElementById('error-message-descripcion');
            errorMessage.innerHTML = 'La descripción es requerida';
        }

        if (zonaValue === '') {
            errors = true;
            const errorMessage = document.getElementById('error-message-zona');
            errorMessage.innerHTML = 'La zona es requerida';
        }

        if (errors) {
            return;
        }

        const body = getBody();

        await saveAnomalyToServer(body);
        navigate('/main-menu');
    };

    useEffect(() => {
        fetchAssignable().then(assignableData => {
            if (Array.isArray(assignableData)) {
                setAsignables(assignableData);
            }
        });
    }, []);

    useEffect(() => {
        if (zonaValue) {
            fetchPlazas(zonaValue).then((plazasData) => {
                if (Array.isArray(plazasData)) {
                    setPlazas(plazasData);
                } else {
                    console.error('Fetched plazas data is not an array:', plazasData);
                }
            });
        } else {
            setPlazas([]);
        }
    }, [zonaValue]);

    const handleDescripcionChange = (e) => {
        setDescripcion(e.target.value);
        const errorMessage = document.getElementById('error-message-descripcion');
        errorMessage.innerHTML = '';
    };

    const handleZonaChange = (e) => {
        setZonaValue(e.target.value);
        // const errorMessage = document.getElementById('error-message-zona');
        // errorMessage.innerHTML = '';
    };

    const handleHechoChange = (e) => {
        setHechoValue(e.target.value);
    };

    const handleTareasChange = (newSelectedValues) => {
        setSelectedValues(newSelectedValues);

        // setTareasValue(e.target.value);
        // xxx
    };

    const handlePlazaChange = (e) => {
        setPlazaValue(e.target.value);
    };

    const handleAsignarAChange = (e) => {
        setAsignarAValue(e.target.value);
    };

    const handleObservacionesChange = (e) => {
        setObservaciones(e.target.value);
    };

    const handleImageCapture = (e) => {
        const files = Array.from(e.target.files);
        if (files.length + capturedImages.length > MAX_IMAGES) {
            alert(`Solo se permiten ${MAX_IMAGES} imágenes`);
            return;
        }

        const newImages = files.map(file => {
            const reader = new FileReader();
            return new Promise((resolve) => {
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                reader.readAsDataURL(file);
            });
        });

        Promise.all(newImages).then(images => {
            setCapturedImages(prevImages => [...prevImages, ...images]);
        });
    };

    const handleDeleteImage = (index) => {
        setCapturedImages(prevImages => prevImages.filter((_, i) => i !== index));
    };

    const capitalizeFirstLetter = (string) => {
        if (string.length === 0) return string;
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const getDate = () => {
        const date = new Date();
        const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
        return new Intl.DateTimeFormat('es-ES', options).format(date);
    }

    const daysOfWeek = ['L', 'M', 'M', 'J', 'V', 'S', 'D'];
    const today = new Date().getDay();
    const adjustedToday = (today === 0) ? 6 : today - 1;

    return (
        <div>
            <Header currentPage="Anomaly" onLogout={handleLogout} onBack={handleBack} />
            <h2>{capitalizeFirstLetter(type)}</h2>
            <div className='text-date'>Hoy es {getDate()}</div>
            <div className="form-container centered">

                <div className='flex'>
                    <label className="inline-label">
                        Comuna/Zona:
                    </label>
                    <FilterableDropdown
                        zones={zones}
                        zonaValue={zonaValue}
                        handleZonaChange={handleZonaChange}
                        isEditable={isEditable}
                        placeholder="Seleccione una zona"
                    />
                </div>
                <div className='flex error-message-form' id="error-message-zona"></div>

                <div className='flex'>
                    <label className="inline-label">
                        Plaza:
                    </label>
                    <FilterableDropdown
                        zones={plazas}
                        zonaValue={plazaValue}
                        handleZonaChange={handlePlazaChange}
                        isEditable={isEditable}
                        placeholder="Seleccione una plaza"
                    />
                </div>

                <div className='flex'>
                    <label className="inline-label">
                        Ubicación:
                    </label>
                    <select
                        value={plazaValue}
                        className="dropdown inline-input"
                        disabled={true}
                    >
                        {plazas.map((plaza) => (
                            <option key={plaza.id} value={plaza.id}>{plaza.name}</option>
                        ))}
                    </select>
                </div>

                <SiNoDropdown
                    zonaValue={plazaValue}
                    handleValueChange={handleHechoChange}
                    isEditable={isEditable}
                    placeholder="Hecho"
                />

                <MultiSelectModal
                    options={options}
                    selectedValues={selectedValues}
                    handleValueChange={handleTareasChange}
                    isEditable={true}
                    title="Tareas realizadas"
                    placeholder="Tareas realizadas"
                />

                <div className='flex error-message-form' id="error-message-plaza"></div>

                <div className="form-group">
                    <label className="inline-label">
                        <>
                            <img src={icon_camera} width="50px" alt="Camera Icon" />
                            {isEditable && (
                                <input
                                    type="file"
                                    accept="image/*"
                                    capture="environment"
                                    style={{ display: 'none' }}
                                    onChange={handleImageCapture}
                                    multiple
                                />
                            )}
                        </>
                    </label>
                    <div className="images-grid">
                        {capturedImages.map((image, index) => (
                            <div key={index} className="image-container">
                                <img className='image-item' src={image} alt={`Captured ${index + 1}`} />
                                {isEditable && (
                                    <button onClick={() => handleDeleteImage(index)}>Eliminar</button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                <div className='flex'>
                    <label className="inline-label">
                        Observaciones:
                    </label>
                    <textarea
                        value={observaciones}
                        onChange={handleObservacionesChange}
                        className="textarea inline-input"
                        readOnly={!isEditable}
                        rows="5"
                    />
                    <span id="error-message-observaciones"></span>
                </div>

                <div id="error-message"></div>
                <div id="error-message-2"></div>

                {isEditable && (
                    <div className="form-group button-save">
                        <button className="submit-button image-button" onClick={handleSave}>
                            <img src={icon_save} width="50px" alt="Guardar" />
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Planilla;
