import './FilterableDropdown.css';

import React, { useState } from 'react';

const FilterableDropdown = ({ zones, zonaValue, handleZonaChange, isEditable, placeholder }) => {
    const [filterText, setFilterText] = useState(zonaValue);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleFilterChange = (e) => {
        setFilterText(e.target.value);
        setIsDropdownOpen(true); // Open the dropdown when typing
    };

    const handleOptionSelect = (zone) => {
        setFilterText(zone.name);
        handleZonaChange({ target: { value: zone.id } });
        setIsDropdownOpen(false); // Close the dropdown when an option is selected
    };

    const filteredZones = filterText
        ? zones.filter(zone =>
            zone.name.toLowerCase().includes(filterText.toLowerCase())
        )
        : zones;

    return (
        <div className="filterable-dropdown">
            <input
                type="text"
                value={filterText}
                onChange={handleFilterChange}
                placeholder={placeholder}
                className="filter-input"
                disabled={!isEditable}
                onFocus={() => {
                    setFilterText('');
                    setIsDropdownOpen(true);
                }}
            />
            {isDropdownOpen && (
                <ul className="dropdown-list">
                    {filteredZones.map((zone) => (
                        <li
                            key={zone.id}
                            onClick={() => handleOptionSelect(zone)}
                            className="dropdown-item"
                        >
                            {zone.name}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FilterableDropdown;
