import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './Login';
import MainMenu from './MainMenu';
import TaskList from './Pages/Planilla';
import Planilla from './Pages/Planilla';
import { UserProvider } from './context/UserContext';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate } from 'react-router-dom';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('salvatoriServiciosToken');
  };

  return (
    <div className="App" >
      <UserProvider>
        <Router>
          {isLoggedIn ? (
            <Routes>
              <Route path="/task-list" element={<TaskList type='created' handleLogout={handleLogout} />} />
              <Route path="/planilla/limpieza" element={<Planilla type='limpieza' handleLogout={handleLogout} />} />
              <Route path="/main-menu" element={<MainMenu handleLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/main-menu" replace />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/login" element={<Login onLogin={handleLogin} onLogout={handleLogout} />} />
              <Route path="*" element={<Navigate to="/login" replace />} />
            </Routes>
          )}
        </Router>
      </UserProvider>
    </div>
  )
};

export default App;
