import React, { useState } from 'react';
import './MultiSelectModal.css'; // Ensure you create this CSS file for styling

const MultiSelectModal = ({ options, selectedValues, handleValueChange, isEditable, title, placeholder }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [tempSelectedValues, setTempSelectedValues] = useState([...selectedValues]);
    const [selectedNames, setSelectedNames] = useState('');

    const handleCheckboxChange = (event) => {
        const value = event.target.value;
        const newSelectedValues = event.target.checked
            ? [...tempSelectedValues, value]
            : tempSelectedValues.filter((selectedValue) => selectedValue !== value);

        setTempSelectedValues(newSelectedValues);
    };

    const handleOkClick = () => {
        console.log(tempSelectedValues);
        const intValues = tempSelectedValues.map(value => parseInt(value, 10));
        console.log(intValues);

        console.log(options);
        const selectedNames = intValues
            .map(value => {
                console.log('value', value);
                return options.find(option => {
                    console.log('option', option, 'value', value);
                    return option.id === value;
                })?.name
            }
            )
            .filter(name => {
                console.log('name', name);
                return name;

            });
console.log('selectedNames', selectedNames);
        
        handleValueChange(tempSelectedValues);
        setIsModalOpen(false);
        setSelectedNames(selectedNames);
    };

    return (
        <div className="flex">
            <label className="inline-label">
                {placeholder}:
            </label>
            <div className="modal-trigger" onClick={() => setIsModalOpen(true)}>
                {selectedNames.length > 0 ? selectedNames.join(', ') : '(Seleccione tareas)'}
            </div>
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <h3>{title}</h3>
                        <ul className="modal-list">
                            {options.map((option) => (
                                <li key={option.id} className="modal-item">
                                    <label>
                                        <input
                                            type="checkbox"
                                            value={option.id}
                                            checked={tempSelectedValues.includes(option.id.toString())}
                                            onChange={handleCheckboxChange}
                                            disabled={!isEditable}
                                        />
                                        {option.name}
                                    </label>
                                </li>
                            ))}
                        </ul>
                        <div className="modal-ok-button-container">
                            <button onClick={handleOkClick} className="modal-ok-button">OK</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MultiSelectModal;
